"use strict";
exports.__esModule = true;
exports.create = exports.Klouds = exports.VERSION = void 0;
var shader_vert_1 = require("./shader.vert");
var shader_frag_1 = require("./shader.frag");
var graphics_1 = require("./graphics");
exports.VERSION = PACKAGE_VERSION;
function normalizeColor(color) {
    var div = document.createElement('div');
    div.style.display = 'none';
    div.style.color = color;
    document.body.appendChild(div);
    var strColors = getComputedStyle(div).color || 'rgb(0, 0, 0)';
    var numColors = strColors.match(/[+-]?\d+(\.\d+)?/g);
    var intColors = numColors
        ? numColors.map(function (a) { return parseInt(a, 10); })
        : [0, 0, 0];
    if (div.parentElement) {
        div.parentElement.removeChild(div);
    }
    if (intColors.length > 3) {
        intColors.length = 3;
    }
    else if (intColors.length < 3) {
        return [0, 0, 0];
    }
    return intColors;
}
function parseColor(color) {
    var result = [0, 0, 0];
    if (typeof color === 'string') {
        normalizeColor(color).forEach(function (color, idx) {
            result[idx] = color / 255;
        });
    }
    else if (Array.isArray(color) && color.length === 3) {
        color.slice(0, 3).forEach(function (color, idx) {
            result[idx] = color / 255;
        });
    }
    return result;
}
var Klouds = /** @class */ (function () {
    function Klouds(options) {
        var _this = this;
        this.isRunning = false;
        this.accumTime = 0;
        this.lastTime = performance.now();
        this.render = function (time) {
            var gl = _this.gl;
            var program = _this.program;
            var buffer = _this.buffer;
            var dt = (time - _this.lastTime) * _this.speed * 0.001;
            _this.accumTime += dt;
            _this.lastTime = time;
            graphics_1.resizeCanvasToDisplaySize(_this.canvas);
            gl.viewport(0, 0, gl.canvas.width, gl.canvas.height);
            gl.useProgram(program.handle);
            gl.bindBuffer(gl.ARRAY_BUFFER, buffer);
            var posAttrib = program.attributes['position'];
            gl.enableVertexAttribArray(posAttrib);
            gl.vertexAttribPointer(posAttrib, 3, gl.FLOAT, false, 0, 0);
            gl.uniform2f(program.uniforms['resolution'], gl.canvas.width, gl.canvas.height);
            gl.uniform1f(program.uniforms['layerCount'], _this.layerCount);
            gl.uniform1f(program.uniforms['time'], _this.accumTime);
            gl.uniform3fv(program.uniforms['bgColor'], _this.bgColor);
            gl.uniform3fv(program.uniforms['cloudColor1'], _this.cloudColor1);
            gl.uniform3fv(program.uniforms['cloudColor2'], _this.cloudColor2);
            gl.drawArrays(gl.TRIANGLES, 0, 6);
            if (_this.isRunning) {
                requestAnimationFrame(_this.render);
            }
        };
        var canvas = this.queryRootElement(options.selector);
        if (!canvas) {
            throw new Error("Invalid options.selector value passed to Klouds");
        }
        this.canvas = canvas;
        var speed = options.speed || 1;
        var layerCount = options.layerCount || 5;
        var cloudColor1 = options.cloudColor1 || [25, 178, 204];
        var cloudColor2 = options.cloudColor2 || [255, 255, 255];
        var bgColor = options.bgColor || [0, 102, 128];
        canvas.style.width = '100%';
        canvas.style.height = '100%';
        this.initGraphics(canvas);
        this.setSpeed(speed);
        this.setLayerCount(layerCount);
        this.setBgColor(bgColor);
        this.setCloudColor1(cloudColor1);
        this.setCloudColor2(cloudColor2);
        this.start();
    }
    Klouds.prototype.queryRootElement = function (selector) {
        if (typeof selector === 'string') {
            var element = document.querySelector(selector);
            if (!element) {
                console.error('options.selector element not found');
                return null;
            }
            else if (!(element instanceof HTMLCanvasElement)) {
                console.error('options.selector element is not a <canvas> element');
                return null;
            }
            else {
                return element;
            }
        }
        else if (selector instanceof HTMLElement) {
            if (selector instanceof HTMLCanvasElement) {
                return selector;
            }
            else {
                console.error('options.selector element is not a <canvas> element');
                return null;
            }
        }
        else {
            console.error('options.selector value is invalid');
            return null;
        }
    };
    Klouds.prototype.initGraphics = function (canvas) {
        var gl = graphics_1.createContext(canvas);
        if (!gl) {
            throw new Error('Failed to create WebGL context');
        }
        var program = graphics_1.createProgram(gl, shader_vert_1["default"], shader_frag_1["default"], ['position'], [
            'resolution',
            'layerCount',
            'time',
            'bgColor',
            'cloudColor1',
            'cloudColor2'
        ]);
        if (!program) {
            throw new Error('Failed to create WebGL program');
        }
        var buffer = gl.createBuffer();
        if (!buffer) {
            gl.deleteProgram(program);
            throw new Error('Failed to create WebGL buffer');
        }
        var posAttrib = program.attributes['position'];
        gl.enableVertexAttribArray(posAttrib);
        gl.vertexAttribPointer(posAttrib, 3, gl.FLOAT, false, 0, 0);
        var bufferData = [
            [-1, -1, 0],
            [1, -1, 0],
            [-1, 1, 0],
            [-1, 1, 0],
            [1, -1, 0],
            [1, 1, 0]
        ];
        gl.bindBuffer(gl.ARRAY_BUFFER, buffer);
        gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(bufferData.reduce(function (prev, cur) { return prev.concat(cur); }, [])), gl.STATIC_DRAW);
        this.gl = gl;
        this.program = program;
        this.buffer = buffer;
    };
    Klouds.prototype.start = function () {
        if (this.isRunning) {
            return;
        }
        this.isRunning = true;
        requestAnimationFrame(this.render);
    };
    Klouds.prototype.stop = function () {
        this.isRunning = false;
    };
    Klouds.prototype.setSpeed = function (speed) {
        this.speed = Math.max(-100, Math.min(100, speed));
    };
    Klouds.prototype.setCloudColor1 = function (color) {
        var c = parseColor(color);
        if (c) {
            this.cloudColor1 = c;
        }
    };
    Klouds.prototype.setCloudColor2 = function (color) {
        var c = parseColor(color);
        if (c) {
            this.cloudColor2 = c;
        }
    };
    Klouds.prototype.setBgColor = function (color) {
        var c = parseColor(color);
        if (c) {
            this.bgColor = c;
        }
    };
    Klouds.prototype.setLayerCount = function (count) {
        this.layerCount = Math.max(1, Math.min(8, count)) / 10;
    };
    Klouds.prototype.getSpeed = function () {
        return this.speed;
    };
    Klouds.prototype.getLayerCount = function () {
        return this.layerCount;
    };
    Klouds.prototype.getBgColor = function () {
        return this.bgColor.slice();
    };
    Klouds.prototype.getCloudColor1 = function () {
        return this.cloudColor1.slice();
    };
    Klouds.prototype.getCloudColor2 = function () {
        return this.cloudColor2.slice();
    };
    return Klouds;
}());
exports.Klouds = Klouds;
function create(options) {
    return new Klouds(options);
}
exports.create = create;
if (window && typeof window.jQuery !== 'undefined') {
    ;
    window.jQuery.fn.Klouds = function (options) {
        options = options || {};
        var elements = this.get();
        var skies = [];
        for (var i = 0; i < elements.length; ++i) {
            options.selector = elements[i];
            skies.push(new Klouds(options));
        }
        return skies;
    };
}
