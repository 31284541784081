"use strict";
exports.__esModule = true;
exports.resizeCanvasToDisplaySize = exports.createProgram = exports.createShader = exports.ShaderType = exports.createContext = void 0;
function createContext(canvas) {
    return canvas.getContext('webgl');
}
exports.createContext = createContext;
var ShaderType;
(function (ShaderType) {
    ShaderType[ShaderType["VERTEX"] = 0] = "VERTEX";
    ShaderType[ShaderType["FRAGMENT"] = 1] = "FRAGMENT";
})(ShaderType = exports.ShaderType || (exports.ShaderType = {}));
function createShader(gl, type, source) {
    var shader = type === ShaderType.VERTEX
        ? gl.createShader(gl.VERTEX_SHADER)
        : gl.createShader(gl.FRAGMENT_SHADER);
    if (!shader) {
        console.error('Failed to create WebGL shader');
        return null;
    }
    gl.shaderSource(shader, source);
    gl.compileShader(shader);
    if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
        console.debug(gl.getShaderInfoLog(shader));
        console.error('Failed to compile WebGL shader');
        gl.deleteShader(shader);
        return null;
    }
    return shader;
}
exports.createShader = createShader;
function createProgram(gl, vertShaderSrc, fragShaderSrc, attributeNames, uniformNames) {
    var program = gl.createProgram();
    if (!program) {
        console.error('Failed to create WebGL program');
        return null;
    }
    var vertexShader = createShader(gl, ShaderType.VERTEX, vertShaderSrc);
    var fragmentShader = createShader(gl, ShaderType.FRAGMENT, fragShaderSrc);
    if (!vertexShader || !fragmentShader) {
        console.error('Failed to create a shader while creating a program');
        return null;
    }
    gl.attachShader(program, vertexShader);
    gl.attachShader(program, fragmentShader);
    gl.linkProgram(program);
    gl.validateProgram(program);
    if (!gl.getProgramParameter(program, gl.LINK_STATUS)) {
        gl.deleteProgram(program);
        gl.deleteShader(vertexShader);
        gl.deleteShader(fragmentShader);
        console.error("Failed to link WebGL program");
        console.debug(gl.getProgramInfoLog(program));
        return null;
    }
    if (!gl.getProgramParameter(program, gl.VALIDATE_STATUS)) {
        gl.deleteProgram(program);
        gl.deleteShader(vertexShader);
        gl.deleteShader(fragmentShader);
        console.error("Failed to validate WebGL program");
        console.debug(gl.getProgramInfoLog(program));
        return null;
    }
    var attributes = {};
    for (var i = 0; i < attributeNames.length; ++i) {
        var name_1 = attributeNames[i];
        var attr = gl.getAttribLocation(program, name_1);
        if (attr === -1) {
            console.error("Program attribute named \"" + name_1 + "\" doesn't exist");
            gl.deleteProgram(program);
            return null;
        }
        attributes[name_1] = attr;
    }
    var uniforms = {};
    for (var i = 0; i < uniformNames.length; ++i) {
        var name_2 = uniformNames[i];
        var uniform = gl.getUniformLocation(program, name_2);
        if (uniform === null) {
            console.error("Program uniform named \"" + name_2 + "\" doesn't exist");
            gl.deleteProgram(program);
            return null;
        }
        uniforms[name_2] = uniform;
    }
    return {
        handle: program,
        attributes: attributes,
        uniforms: uniforms
    };
}
exports.createProgram = createProgram;
function resizeCanvasToDisplaySize(canvas) {
    var width = canvas.clientWidth;
    var height = canvas.clientHeight;
    if (canvas.width !== width || canvas.height !== height) {
        canvas.width = width;
        canvas.height = height;
        return true;
    }
    return false;
}
exports.resizeCanvasToDisplaySize = resizeCanvasToDisplaySize;
